<template>
  <base-dialog @cancel="cancelDialog" :width="dialogWidth">
    <div slot="header">定价设置</div>
    <div slot="body">
      <a-form
        :form="form.fc"
        v-bind="formItemLayout">
        <a-row>
          <a-col :span="24">
            <span class="info-title">基础信息</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="资源厂商">
              <span>芒果云</span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="资源类型">
              <span>{{ params.row.type_name }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="资源">
              <span>{{ params.row.product_name }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span class="info-title">定价信息</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="计费方式">
              <span>{{ params.row.billing_type === 'prepaid' ? '包年包月套餐' : '按量计费' }}</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div :style="{width: (dialogWidth - 70) + 'px'}">
        <div v-for="(item,index) in basicData" :key="index">
          <a-row>
            <a-col :span="12">
              <a-form-item :label="'定价表-基础服务计费项' + (index+1)" />
            </a-col>
          </a-row>
          <vxe-grid
            :data="[item]"
            :columns="getColumns(index)"
            row-id="item_id" />
        </div>
        <div v-for="(item,index) in extraData" :key="index">
          <a-row>
            <a-col :span="12">
              <a-form-item :label="'定价表-增值服务计费项' + (index+1)" />
            </a-col>
          </a-row>
          <vxe-grid
            :data="[item]"
            :columns="getColumns(index)"
            row-id="item_id" />
        </div>
      </div>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">确认定价</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'CloudProductPricePrepaidConfigDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
      },
      formItemLayout: {
        wrapperCol: {
          span: 17,
        },
        labelCol: {
          span: 7,
        },
      },
      dialogWidth: 1000,
      basicData: [],
      extraData: [],
    }
  },
  created () {
    this.fetchDetail()
  },
  methods: {
    fetchDetail () {
      this.$http.get('/v2/cloud_product_prepaid_items/config', {
        params: {
          product_id: this.params.row.product_id,
        },
      }).then(res => {
        console.log('res.data:', res.data)
        // if (res.data.err_code !== 0) {
        //   this.$message.error(res.data.message)
        //   return
        // }
        if (res.data) {
          const items = res.data.items || []
          const basicKeysItems = items.filter(item => item.billing_item_type === 1).sort((a, b) => a.seq - b.seq)
          if (basicKeysItems.length) {
            this.basicData = basicKeysItems
          }
          const extraKeysItems = items.filter(item => item.billing_item_type === 2).sort((a, b) => a.seq - b.seq)
          if (extraKeysItems.length) {
            this.extraData = extraKeysItems
          }
        }
      })
    },
    getColumns (index) {
      return [
        {
          field: 'billing_item',
          title: '计费项' + (index + 1) + '名称',
          align: 'center',
        },
        {
          field: 'price',
          title: '芒果云价格(套餐)',
          align: 'center',
          slots: {
            default: ({ row }) => {
              return [
                '￥',
                <a-input-number
                  v-model={row.price}
                  precision={4}
                  min={0}
                />,
                <span>/月</span>,
                <span class="text-red" v-show={ !row.is_price_set }>暂未设置价格</span>,
              ]
            },
          },
        },
      ]
    },
    doConfig (data) {
      return this.$http.post('/v2/cloud_product_prepaid_items/set-price', data)
    },
    async handleConfirm () {
      this.loading = true
      try {
        const data = {
          product_id: this.params.row.product_id,
          items: [...this.basicData, ...this.extraData],
        }
        console.log('data: ', data)

        this.loading = true
        await this.doConfig(data)
        this.loading = false
        this.$message.success('定价成功')
        this.cancelDialog()
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-form-item{
  margin-bottom: 0 !important;
}
.text-red{
  color: red;
  margin-left: 10px;
}
</style>
