<template>
    <div class="doc-menu">
        <div class="left">
          <a-input v-model="key" placeholder="搜索产品" class="search-input" allowClear @change="handleChange" />
          <ul class="doc-menu-aside">
            <li class="menu-aside-item" v-for="(item,index) in category" :key="index" :class="{active:activeIndex === index}" @click="changeIndex(index)">
              <!-- <img class="img" :src="item.icon" alt="" /> -->
              <icon class="icon" :type="item.icon" />
              <span class="text">{{item.name}}</span>
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="doc-menu-main">
            <div class="doc-menu-panel" v-for="(item,index) in menu" :key="item.id" @mouseenter="changeIndex(index)">
              <div class="doc-menu-title">{{item.name}}</div>
              <ul class="doc-menu-list">
                <li class="menu-list-item" v-for="c in item.children" :key="c.id" @click="handleClick(c)">
                  {{c.name}} <a-icon type="right" class="item-icon" />
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'DocMenu',
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      category: [],
      menu: [],
      activeIndex: 0,
      key: '',
    }
  },
  mounted () {
    this.category = _.cloneDeep(this.menus)
    this.menu = _.cloneDeep(this.menus)
  },
  methods: {
    changeIndex (index) {
      this.activeIndex = index
    },
    handleClick (item) {
      this.$router.push({
        name: 'documentsDetail',
        query: {
          space: item.space,
        },
      })
    },
    handleClear () {
      this.key = ''
      this.menu = _.cloneDeep(this.menus)
    },
    filterTreeData (data, keyword) {
      return _.filter(data, node => {
        if (node.children && node.children.length > 0) {
          node.children = this.filterTreeData(node.children, keyword)
        }
        return node.name.includes(keyword) || (node.children && node.children.length > 0)
      })
    },
    handleChange (val) {
      console.log('val=', val)
      if (val === '') {
        this.handleClear()
        return
      }
      this.menu = this.filterTreeData(_.cloneDeep(this.menus), this.key)
      console.log('this.menu=', this.menu)
      this.category = _.cloneDeep(this.menu)
    },
  },
}
</script>

<style lang="scss" scoped>
.doc-menu{
  display: flex;
  padding: 2.5vw 18.75vw;
  background: #FFF;

  .left{
    display: flex;
    flex-direction: column;
    width: 15.625vw;
    min-width: 15.625vw;
    margin-right: 2.083vw;

    .search-input{
      ::v-deep .ant-input{
        box-sizing: border-box;
        width: 15.625vw;
        height: 2.188vw;
        border: 1px solid #E5E5E5;
        border-radius: 0;
      }
      margin-bottom: 0.625vw;
    }

    .doc-menu-aside{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      background: rgba(238, 240, 245, 0.3);

      .menu-aside-item{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 2.5vw;
        margin-bottom: 0.625vw;
        padding-left: 0.833vw;
        cursor: pointer;

        &.active{
          .icon{
            color: #0066FE;
          }
          .text{
            color: #0066FE;
          }
          &::before{
            position: absolute;
            top: 0;
            left: 0;
            width: 0.104vw;
            height: 2.5vw;
            background: #0066FE;
            content: '';
          }
        }
        &:hover{
          .icon{
            color: #0066FE;
          }
          .text{
            color: #0066FE;
          }
        }

        .img{
          width: 1.25vw;
          height: 1.25vw;
        }

        .icon{
          font-size: 1.25vw;
        }

        .text{
          height: 0.833vw;
          margin-left: 0.417vw;
          color: #262626;
          font-weight: 500;
          font-size: 0.833vw;
          line-height: 0.833vw;
        }
      }
    }
  }

  .right{
    display: flex;
    flex: 1;

    .doc-menu-main{
      display: flex;
      flex-direction: column;
      width: 100%;

      .doc-menu-panel{
        display: flex;
        flex-direction: column;
        margin-bottom: 2.083vw;

        &:last-child{
          margin-bottom: 0;
        }

        .doc-menu-title{
          height: 1.146vw;
          margin-bottom: 0.417vw;
          color: #646566;
          font-size: 0.729vw;
          line-height: 1.146vw;
        }

        .doc-menu-list{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          box-sizing: border-box;
          border: 1px solid #E5E5E5;
          border-radius: 0.208vw;
          margin: 0;
          padding: 0;
          list-style: none;

          .menu-list-item{
            display: flex;
            align-items: center;
            padding: 1.042vw;
            color: #222;
            font-size: 0.833vw;
            line-height: 1.25vw;

            .item-icon{
              display: none;
              margin-left: 0.625vw;
            }

            &:hover{
              color: #0066FE;
              box-shadow: 0 0.104vw 0.417vw 0 rgba(0, 0, 0, 0.15);
              cursor: pointer;

              .item-icon{
                display: block;
                color: #0066FE;
              }
            }
          }
        }
      }
    }
  }
}
</style>
