<template>
  <div class="home">
    <banner />
    <product-superiority />
    <partner />
  </div>
</template>

<script>
import Partner from '@/components/Partner'
import Banner from '@/views/portal/about/components/Banner'
import ProductSuperiority from '@/views/portal/about/components/ProductSuperiority'
export default {
  name: 'About',
  components: {
    Banner,
    ProductSuperiority,
    Partner,
  },
}
</script>

<style lang="scss" scoped>
.home{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
