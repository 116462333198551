import i18n from '@/locales'
export const surpportLb = ['onecloud', 'aliyun', 'qcloud', 'huawei', 'aws', 'openstack', 'azure', 'google']

export const CHARGE_TYPE = {
  postpaid: i18n.t('network.text_256'),
  prepaid: i18n.t('network.text_257'),
  bandwidth: i18n.t('network.text_194'),
  traffic: i18n.t('network.text_193'),
}

export const LB_SPEC = {
  aliyun: [
    { key: 'slb.s1.small', label: i18n.t('network.text_260') },
    { key: 'slb.s2.small', label: i18n.t('network.text_261') },
    // { key: 'slb.s2.medium', label: i18n.t('network.text_262') },
    { key: 'slb.s3.small', label: i18n.t('network.text_263') },
    // { key: 'slb.s3.medium', label: i18n.t('network.text_264') },
    // { key: 'slb.s3.large', label: i18n.t('network.text_265') },
  ],
  qcloud: [
    { key: 'clb.c2.medium', label: '标准型规格' },
    { key: 'clb.c3.small', label: '高阶型1规格' },
    { key: 'clb.c3.medium', label: '高阶型2规格' },
    { key: 'clb.c4.small', label: '超强型1规格' },
    { key: 'clb.c4.medium', label: '超强型2规格' },
    { key: 'clb.c4.large', label: '超强型3规格' },
    { key: 'clb.c4.xlarge', label: '超强型4规格' },
    { key: 'clb.s1.share', label: '共享型' },
  ],
  mgecloud: [
    { key: '1', label: '普通型' },
    { key: '2', label: '优享型 I' },
    { key: '3', label: '优享型 II' },
    { key: '4', label: '高端型 I' },
    { key: '5', label: '高端型 II' },
    { key: '6', label: '旗舰型' },
    { key: '10', label: '简约型' },
  ],
  aws: [
    { key: 'application', label: i18n.t('network.text_266') },
    { key: 'network', label: i18n.t('network.text_267') },
  ],
  google: [
    { key: 'regional_http_lb', label: i18n.t('network.lb.google.spec.regional_http_lb') },
    { key: 'regional_udp', label: i18n.t('network.lb.google.spec.regional_udp') },
    { key: 'regional_tcp', label: i18n.t('network.lb.google.spec.regional_tcp') },
  ],
}
