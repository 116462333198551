<template>
    <div class="product-superiority">
        <div class="title">产品优势</div>
        <div class="product-item">
            <div class="content">
                <div class="name">完善的媒体云产品体系</div>
                <div class="desc">
                  芒果云芒果云以混合云架构实现全国算力算网互联互通，通过芒果融创超高清云制播、可交互流媒体、人工智能技术实现云化、智能化、虚拟化内容生产的解决方案与服务。围绕传媒行业，助力企业实现数字化转型。
                </div>
            </div>
            <img class="img right-radius" src="https://img.cdn.mg.xyz/mgcloud/photo/image/about/about-product-img1.jpg" alt="" />
        </div>
        <div class="product-item">
          <img class="img left-radius" src="https://img.cdn.mg.xyz/mgcloud/photo/image/about/about-product-img2.jpg" alt="" />
            <div class="content">
                <div class="name">专业的音视频解决方案支持</div>
                <div class="desc">
                  芒果云拥有成熟的端-边-云算力体系，丰富的云基础设施配置；提供精准的资源调度及精细的运营服务。围绕实时互动、视频服务、视频终端、媒体处理等，提供云直播、云渲染、多云CDN、超高清云直播等音视频解决方案。
                </div>
            </div>
        </div>
        <div class="product-item">
            <div class="content">
                <div class="name">强大的生态服务</div>
                <div class="desc">
                  芒果云强大的生态体系和多样化的服务支持，助您无忧上云；数字化成功的关键是以云原生的思维践行云原生，全数字化、全云化、AI驱动，一切皆服务；致力于让云无处不在，让智能无所不及，共建智能世界云底座；面向未来的智能世界，数字化是企业发展的必由之路。
                </div>
            </div>
            <img class="img right-radius" src="https://img.cdn.mg.xyz/mgcloud/photo/image/about/about-product-img3.jpg" alt="" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'ProductSuperiority',
}
</script>

<style lang="scss" scoped>
.product-superiority{
  display: flex;
  flex-direction: column;
  padding: 3.75vw 18.75vw 0;
  background: #FFF;

  .title{
    font-size: 1.563vw;
    font-weight: 500;
    height: 1.875vw;
    line-height: 1.875vw;
    color: #262626;
    text-align: center;
  }

  .product-item{
    display: flex;
    justify-content: space-between;
    height: 17.813vw;
    margin-top: 2.5vw;
    border: 1px solid #DFE1E6;
    border-radius: 0.417vw;
    box-sizing: border-box;
    overflow: hidden;

    .img{
      width: 23.75vw;
      height: 17.813vw;
      &.left-radius{
        border-top-left-radius: 0.417vw;
        border-bottom-left-radius: 0.417vw;
      }
      &.right-radius{
        border-top-right-radius: 0.417vw;
        border-bottom-right-radius: 0.417vw;
      }
    }

    .content{
      padding: 3.229vw 1.875vw;
      display: flex;
      flex-direction: column;
      width: 38.75vw;

      .name{
        height: 1.146vw;
        color: #262626;
        font-weight: 500;
        font-size: 0.833vw;
        line-height: 1.146vw;
      }

      .desc{
        margin-top: 0.833vw;
        color: #646566;
        font-weight: normal;
        font-size: 0.729vw;
        line-height: 1.146vw;
      }

    }
  }
}
</style>
