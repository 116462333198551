<template>
  <div class="order-detail">
    <div class="order-detail-box">
      <h4>订单概要</h4>
      <div class="box-content">
        <div class="box-content-row">
          <div class="row-item">
            <div class="row-item-label">订单编号：</div>
            <div class="row-item-value">{{ data.id }}</div>
          </div>
          <div class="row-item">
            <div class="row-item-label">订单类型：</div>
            <div class="row-item-value">
              {{ getOrderTypeText(data.order_type) }}
            </div>
          </div>
        </div>
        <div class="box-content-row">
          <div class="row-item">
            <div class="row-item-label">订单创建时间：</div>
            <div class="row-item-value">{{ formatTime(data.created_at) }}</div>
          </div>
          <div class="row-item">
            <div class="row-item-label">支付时间：</div>
            <div class="row-item-value">{{ formatTime(data.paid_at) }}</div>
          </div>
        </div>
        <div class="box-content-row">
          <div class="row-item">
            <div class="row-item-label">支付状态：</div>
            <div class="row-item-value">
              {{ getOrderStatusText(data.order_status) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-detail-box">
      <h4>订单详情</h4>
      <div class="box-card" style="display: block">
        <a-table
          :columns="columns"
          :data-source="data.sub_orders.data"
          :pagination="false">
          <template slot="product_type" slot-scope="text, record">
            <div>
              {{ getOrderProductsText(record.product_type)
              }}<span v-if="data.billing_type">（{{ getBillTypeText(record.billing_type) }}）</span>
            </div>
            <div>
              实例名称：{{ record.instance_name }}（{{ record.instance_id }}）
            </div>
          </template>
          <template slot="web_configuration" slot-scope="text, record">
            <!-- 高阶产品的订单 -->
            <template v-if="record.provider === 'CloudProduct'">
              <div v-for="(value,key) in record.web_configuration" :key="key">
                <span>{{ key }}</span>：
                <span>{{ value }}</span>
              </div>
            </template>
            <template v-else>
              <!-- 云服务器ecs -->
              <div v-if="record.web_configuration.instance_info">
                实例：{{ record.web_configuration.instance_info }}
              </div>
              <div v-if="record.web_configuration.system_disk_info">
                系统盘：{{ record.web_configuration.system_disk_info }}
              </div>
              <!-- 快照 -->
              <div v-if="record.web_configuration.snapshot_size">
                快照大小：{{ record.web_configuration.snapshot_size }}
              </div>
              <div v-if="record.web_configuration.snapshot_disk">
                硬盘：{{ record.web_configuration.snapshot_disk }}
              </div>
              <!-- 弹性公网IP：带宽/区域 -->
              <div v-if="record.web_configuration.band_width">
                带宽：{{ record.web_configuration.band_width }}MB
              </div>
              <div v-if="record.web_configuration.cpu">
                CPU：{{ record.web_configuration.cpu }}核
              </div>
              <div v-if="record.web_configuration.region_name">
                区域：{{ record.web_configuration.region_name }}
              </div>
              <div v-if="record.web_configuration.os_type">
                操作系统：{{ record.web_configuration.os_type }}
              </div>
              <div v-if="record.web_configuration.memory">
                内存：{{ record.web_configuration.memory }}GB
              </div>
              <div v-if="record.web_configuration.network_type">
                网络类型：{{ record.web_configuration.network_type }}
              </div>
              <div v-if="record.web_configuration.data_disk_info">
                数据盘：{{ record.web_configuration.data_disk_info }}
              </div>
              <div v-if="record.web_configuration.sec_group_info">
                安全组：{{ record.web_configuration.sec_group_info }}
              </div>
              <!-- 负载均衡 -->
              <div v-if="record.web_configuration.ip_network_info">
                指定IP子网：{{ record.web_configuration.ip_network_info }}
              </div>
              <div v-if="record.web_configuration.address_type">
                网络：{{ record.web_configuration.address_type }}
              </div>
              <div v-if="record.web_configuration.ip">
                IP版本：{{ record.web_configuration.ip }}
              </div>
              <div v-if="record.web_configuration.vpc">
                VPC：{{ record.web_configuration.vpc }}
              </div>
              <!-- NAT网关 -->
              <div v-if="record.web_configuration.duration">
                到期释放：{{ record.web_configuration.duration }}
              </div>
              <div v-if="record.web_configuration.spec">
                规格：{{ specOpts[record.web_configuration.spec].label }}
              </div>
              <div v-if="record.web_configuration.charge_type">
                网络计费方式：{{ record.web_configuration.charge_type }}
              </div>
              <!-- 块存储/硬盘：区域/介质类型/存储类型/容量-->
              <div v-if="record.web_configuration.fs_format">
                存储类型：{{ record.web_configuration.fs_format }}
              </div>
              <div v-if="record.web_configuration.disk_type">
                介质类型：{{
                  record.web_configuration.disk_type === "sys"
                    ? $t("compute.text_49")
                    : $t("compute.text_50")
                }}
              </div>
              <div v-if="record.web_configuration.disk_size">
                <!-- 容量：{{ record.web_configuration.disk_size/1024 }}GB -->
                容量：{{
                  record.web_configuration.disk_size
                    ? sizestr(record.web_configuration.disk_size, "M", 1024)
                    : "-"
                }}
              </div>
              <!-- 新建时还没有这个字段 -->
              <div v-if="record.web_configuration.guest">
                指定虚拟机：{{ record.web_configuration.guest }}
              </div>
            </template>
          </template>
          <template slot="billing_type" slot-scope="text, record">
            {{ getBillTypeText(record.billing_type) }}
          </template>
          <template slot="period_time" slot-scope="text, record">
            <div>
              {{
                isPostpaid(record.billing_type)
                  ? formatTime()
                  : formatTime(record.period_start)
              }}
            </div>
            <div>
              {{
                isPostpaid(record.billing_type)
                  ? formatTime()
                  : formatTime(record.period_end)
              }}
            </div>
          </template>
          <template slot="unit_price_web" slot-scope="text">
            {{ text }}元
          </template>
        </a-table>
      </div>
    </div>
    <div class="order-detail-footer">
      <div class="footer-item">
        应付金额：
        <span class="count">{{ data.unit_price_web }}</span>
        元
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ORDER_STATUS_MAP, ORDER_TYPE_MAP } from '@Cost/constants'
import { LB_SPEC } from '@Network/views/lb/constants'
import { sizestr } from '@/utils/utils'
// import i18n from '@/locales'

function groupProviderObj (originObj) {
  const newObj = {}

  for (const provider in originObj) {
    const providerConfigs = originObj[provider]
    for (const config of providerConfigs) {
      newObj[config.key] = config
    }
  }
  return newObj
}

export default {
  name: 'OrderDetail',
  props: {
    data: {
      type: Object,
      required: true,
    },
    orderProductList: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      columns: [
        {
          title: '产品',
          dataIndex: 'product_type',
          key: 'product_type',
          scopedSlots: { customRender: 'product_type' },
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
        },
        {
          title: '具体配置',
          dataIndex: 'web_configuration',
          key: 'web_configuration',
          scopedSlots: { customRender: 'web_configuration' },
        },
        {
          title: '计费方式',
          key: 'billing_type',
          dataIndex: 'billing_type',
          scopedSlots: { customRender: 'billing_type' },
        },
        {
          title: '服务起止时间',
          key: 'period_time',
          scopedSlots: { customRender: 'period_time' },
        },
        {
          title: '价格',
          key: 'unit_price_web',
          dataIndex: 'unit_price_web',
          scopedSlots: { customRender: 'unit_price_web' },
        },
      ],
      // specOpts: arrayToObj(LB_SPEC.aliyun, 'key'),
      specOpts: groupProviderObj(LB_SPEC),
      sizestr: sizestr,
    }
  },
  methods: {
    // 获取产品类型label
    getOrderProductsText (orderProducts) {
      let text = ''
      this.orderProductList.forEach((item) => {
        if (item.key === orderProducts) {
          text = item.label
        }
      })
      return text
    },
    isPostpaid (type) {
      return type === 'postpaid'
    },
    formatTime (time) {
      return time ? moment(time).format() : '-'
    },
    getOrderStatusText (status) {
      let text = ''
      Object.keys(ORDER_STATUS_MAP).forEach((key) => {
        if (ORDER_STATUS_MAP[key].key === status.toString()) {
          text = ORDER_STATUS_MAP[key].label
        }
      })
      return text
    },
    getOrderTypeText (type) {
      let text = ''
      Object.keys(ORDER_TYPE_MAP).forEach((key) => {
        if (ORDER_TYPE_MAP[key].key === type.toString()) {
          text = ORDER_TYPE_MAP[key].label
        }
      })
      return text
    },
    getBillTypeText (type) {
      const obj = { postpaid: '按量付费', prepaid: '包年包月' }
      return obj[type]
    },
  },
}
</script>
<style lang="scss" scoped>
.order-detail {
  width: 100%;
  height: 100%;

  .order-detail-box {
    width: 100%;
    margin-bottom: 30px;

    .box-content {
      width: 100%;
      margin-top: 20px;
      font-size: 14px;

      .box-content-row {
        display: flex;
        width: 100%;
        margin-bottom: 20px;

        .row-item {
          display: flex;
          width: 50%;

          .row-item-label {
            width: 100px;
          }
        }
      }
    }

    .box-card {
      display: flex;
      width: 100%;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid #ebeef5;
      background-color: #fff;
      overflow: hidden;
      color: #303133;
      transition: 0.3s;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .box-card-column {
        &-header {
          padding: 0 5px;
          display: flex;
          align-items: center;
          height: 40px;
          color: #999;
          font-weight: bold;
          border-bottom: 1px solid #e1e1e1;
        }

        &-body {
          padding: 10px 5px 5px;

          .item {
            margin-bottom: 4px;
          }
        }
      }

      .flex1 {
        flex: 1;
      }
      .flex2 {
        flex: 2;
      }
      .flex3 {
        flex: 3;
      }
      .flex4 {
        flex: 4;
      }
    }
  }

  .order-detail-footer {
    .footer-item {
      text-align: right;
      font-size: 22px;

      .count {
        font-size: 30px;
        color: red;
        font-weight: bold;
      }
    }
  }
}
</style>
