// 最大计费项
export const maxBillingItem = 20
// 最大计费表项
export const maxBillingTableItem = 20
// 中文名称，根据【最大计费表项】进行扩展
export const chineseNums = {
  0: '零',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
  10: '十',
  11: '十一',
  12: '十二',
  13: '十三',
  14: '十四',
  15: '十五',
  16: '十六',
  17: '十七',
  18: '十八',
  19: '十九',
  20: '二十',
}

// 流量、公网带宽、带宽峰值、容量、请求次数
export const billingItemOpts = [
  { label: '流量', value: '流量' },
  { label: '公网带宽', value: '公网带宽' },
  { label: '带宽峰值', value: '带宽峰值' },
  { label: '容量', value: '容量' },
  { label: '请求次数', value: '请求次数' },
  { label: '自定义', value: '自定义', custom: true },
]
// 1-阶梯计费 2-常规计费
export const billingModeOpts = [
  { label: '阶梯计费', value: 1 },
  { label: '常规计费', value: 2 },
]

// GB、Mbps、次、万次、个、张、分钟
export const unitOpts = [
  { label: '请选择', value: '' },
  // { label: 'GB', value: 'GB' },
  // { label: 'Mbps', value: 'Mbps' },
  // { label: '次', value: '次' },
  // { label: '万次', value: '万次' },
  // { label: '个', value: '个' },
  // { label: '张', value: '张' },
  // { label: '分钟', value: '分钟' },
]
// /GB、/Mbps/天、/Mbps/小时、/次、/万次、/个、/张、/分钟
export const billingUnitOpts = [
  { label: '请选择', value: '' },
  // { label: '/GB', value: '/GB' },
  // { label: '/Mbps/天', value: '/Mbps/天' },
  // { label: '/Mbps/小时', value: '/Mbps/小时' },
  // { label: '/次', value: '/次' },
  // { label: '/万次', value: '/万次' },
  // { label: '/个', value: '/个' },
  // { label: '/张', value: '/张' },
  // { label: '/分钟', value: '/分钟' },
]
