import Layout from '@/layouts/RouterView'
import store from '@/store'
// import i18n from '@/locales'

const ResourceRegion = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/resource-region')
const CloudProductType = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/cloud-product-type')
const CloudProduct = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/cloud-product')
const CloudProductPrice = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/cloud-product-price')
const ResourceSpecification = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/resource-specification')
const Product = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/product')
const ProductCreateOrUpdate = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/product/CreateOrUpdate')
const ProductPrice = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/product-price')
const ProductPriceCreateOrUpdate = () => import(/* webpackChunkName: "product" */ /* webpackPrefetch: true */ '@Product/views/product-price/CreateOrUpdate')

export default {
  index: 1600,
  meta: {
    label: '产品中心',
    icon: 'menu-product',
  },
  menus: [
    {
      meta: {
        label: '基础配置管理',
      },
      submenus: [
        {
          path: '/resource-region',
          meta: {
            label: '地域管理',
            // permission: 'resource_regions_list',
            hidden: () => {
              if (store.getters.userInfo.user_type === 'customer') {
                return true
              }
              if (store.getters.userInfo.name.toLowerCase() !== 'admin') {
                return true
              }
            },
          },
          component: Layout,
          children: [
            {
              name: 'ResourceRegion',
              path: '',
              meta: {},
              component: ResourceRegion,
            },
          ],
        },
        {
          path: '/resource-specification',
          meta: {
            label: '云资源规格',
            // permission: 'resource_specifications_list',
            hidden: () => {
              if (store.getters.userInfo.user_type === 'customer') {
                return true
              }
              if (store.getters.userInfo.name.toLowerCase() !== 'admin') {
                return true
              }
            },
          },
          component: Layout,
          children: [
            {
              name: 'ResourceSpecification',
              path: '',
              meta: {},
              component: ResourceSpecification,
            },
          ],
        },
        {
          path: '/cloud-product-type',
          meta: {
            label: '云产品类型',
            permission: 'cloud_product_types_list',
            // hidden: () => {
            //   if (store.getters.userInfo.user_type === 'customer') {
            //     return true
            //   }
            //   if (store.getters.userInfo.name.toLowerCase() !== 'admin') {
            //     return true
            //   }
            // },
          },
          component: Layout,
          children: [
            {
              name: 'CloudProductType',
              path: '',
              meta: {},
              component: CloudProductType,
            },
          ],
        },
      ],
    },
    {
      meta: {
        label: '产品管理',
      },
      submenus: [
        {
          path: '/product',
          meta: {
            label: '云资源管理',
            permission: 'products_list',
            hidden: () => {
              if (store.getters.userInfo.user_type === 'customer') {
                return true
              }
            },
          },
          component: Layout,
          children: [
            {
              name: 'Product',
              path: '',
              meta: {},
              component: Product,
            },
            {
              name: 'ProductCreateOrUpdate',
              path: 'createOrUpdate',
              meta: {},
              component: ProductCreateOrUpdate,
            },
          ],
        },
        {
          path: '/cloud-product',
          meta: {
            label: '云产品管理',
            permission: 'cloud_products_list',
            // hidden: () => {
            //   if (store.getters.userInfo.user_type === 'customer') {
            //     return true
            //   }
            // },
          },
          component: Layout,
          children: [
            {
              name: 'CloudProduct',
              path: '',
              meta: {},
              component: CloudProduct,
            },
          ],
        },
      ],
    },
    {
      meta: {
        label: '定价管理',
      },
      submenus: [
        {
          path: '/product-price',
          meta: {
            label: '云资源定价',
            permission: 'product_prices_list',
            hidden: () => {
              if (store.getters.userInfo.user_type === 'customer') {
                return true
              }
            },
          },
          component: Layout,
          children: [
            {
              name: 'ProductPrice',
              path: '',
              meta: {},
              component: ProductPrice,
            },
            {
              name: 'ProductPriceCreateOrUpdate',
              path: 'createOrUpdate',
              meta: {},
              component: ProductPriceCreateOrUpdate,
            },
          ],
        },
        {
          path: '/cloud-product-price',
          meta: {
            label: '云产品定价',
            permission: 'cloud_product_prices_list',
            // hidden: () => {
            //   if (store.getters.userInfo.user_type === 'customer') {
            //     return true
            //   }
            // },
          },
          component: Layout,
          children: [
            {
              name: 'CloudProductPrice',
              path: '',
              meta: {},
              component: CloudProductPrice,
            },
          ],
        },
      ],
    },
  ],
}
