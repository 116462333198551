<template>
  <base-dialog @cancel="cancelDialog" :width="1000">
    <div slot="header">新建{{ $t('dictionary.network_mapping') }}</div>
    <div slot="body">
      <a-form
        v-bind="formItemLayout"
        :form="form.fc">
        <a-form-item label="运营商">
          <a-select placeholder="请选择运营商" v-decorator="decorators.operator_key">
            <a-select-option v-for="item of this.getDictDatas(DICT_TYPE.NETWORK_OPERATOR)" :key="item.value" :value="item.value">
              {{item.label}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <area-selects
          class="mb-0"
          ref="areaSelects"
          :wrapperCol="formItemLayout.wrapperCol"
          :labelCol="formItemLayout.labelCol"
          :names="areaselectsName"
          :replace_list_key="replace_list_key"
          :cloudregionParams="regionParams"
          :providerParams="cloudProviderParams"
          :isRequired="true"
          :region.sync="regionList"
          filterBrandResource="network_manage"
          @change="cloudregionChange" />
        <ip-subnet
          :label="$t('network.text_211')"
          :isRequired="true"
          :labelCol="formItemLayout.labelCol"
          :wrapperCol="formItemLayout.wrapperCol"
          :decorator="decorators"
          :vpcParams="vpcParams"
          :networkParams="networkParams"
          :vpcResourceMapper="vpcResourceMapper"
          :showIpConfig="cloudEnv !== 'public'" />
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import * as R from 'ramda'
import { mapGetters } from 'vuex'
import IpSubnet from '@Network/sections/IpSubnet'
import AreaSelects from '@/sections/AreaSelects'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'CreateNetworkMappingDialog',
  components: {
    AreaSelects,
    IpSubnet,
  },
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
      },
      decorators: {
        area_mapping_id: ['area_mapping_id'],
        operator_key: [
          'operator_key',
          {
            rules: [{ required: true, message: '请选择运营商' }],
          },
        ],
        vpc: [
          'vpc',
          {
            rules: [{ required: true, message: this.$t('network.text_212') }],
          },
        ],
        network: [
          'network',
          {
            rules: [{ required: true, message: this.$t('network.text_212') }],
          },
        ],
        ip_addr: [
          'ip_addr',
          {
            validateFirst: true,
            rules: [
              { required: true, message: this.$t('network.text_217') },
              { validator: this.$validate('IPv4') },
            ],
          },
        ],
        bandwidth: [
          'bandwidth',
          {
            initialValue: 30,
          },
        ],
      },
      formItemLayout: {
        wrapperCol: {
          span: 18,
        },
        labelCol: {
          span: 4,
        },
      },
      cloudEnv: 'public',
      selectedRegionItem: {},
      regionList: {},
      bgp_type: undefined,
      manager: '',
    }
  },
  computed: {
    ...mapGetters(['isAdminMode', 'scope', 'userInfo']),
    regionParams () {
      return {
        product_code: 'eip',
      }
    },
    networkParams () {
      const ret = {
        limit: 0,
        scope: this.scope,
        bgp_type: this.bgp_type,
      }
      if (this.manager) {
        ret.manager = this.manager
        return ret
      }
      if (
        this.cloudEnv === 'onpremise' &&
        !R.isEmpty(this.selectedRegionItem)
      ) {
        ret.server_type = 'eip'
        ret.area_mapping_id = this.selectedRegionItem.id
        ret.replace_list_key = 'cloudregion_id'
        return ret
      }
      return ret
    },
    vpcParams () {
      const params = {
        scope: this.scope,
        area_mapping_id: this.selectedRegionItem.id,
        replace_list_key: 'cloudregion_id',
      }
      if (this.selectedRegionItem.provider === 'HCS') {
        params['@external_access_mode'] = 'eip'
        params.show_emulated = true
      }
      if (this.isAdminMode) {
        params.project_domain = this.domain_id
        delete params.scope
      }
      return params
    },
    areaselectsName () {
      // if (this.cloudEnv === 'private' || this.cloudEnv === 'onpremise') {
      return ['cloudregion']
      // }
      // return ['provider', 'cloudregion']
    },
    replace_list_key () {
      return 'cloudregion'
    },
    cloudProviderParams () {
      const param = {
        scope: this.scope,
        cloudEnv: this.cloudEnv,
      }
      if (this.isAdminMode) {
        param.project_domain_id = this.domain_id
        delete param.scope
      }
      return param
    },
  },
  methods: {
    cloudregionChange (data) {
      // 拿到所有表单数据
      this.selectedRegionItem = this.regionList[data.cloudregion?.id]
      // const formDate = this.form.fc.getFieldsValue()
      // formDate.area_mapping_id = data.cloudregion?.id
      // this.form.fc.setFieldsValue({ area_mapping_id: data.cloudregion?.id })
    },
    vpcResourceMapper (data) {
      // if (this.selectedRegionItem?.provider === HYPERVISORS_MAP.cloudpods.provider) {
      //   data = data.filter(item => item.external_id === 'default')
      //   return data
      // }
      return data
    },
    doCreate (data) {
      return this.params.onManager('create', {
        managerArgs: {
          data,
        },
      })
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.form.fc.validateFields()
        this.loading = true
        const data = {
          area_mapping_id: this.selectedRegionItem.id,
          network_id: values.network,
          operator_key: values.operator_key,
        }
        await this.doCreate(data)
        this.loading = false
        this.cancelDialog()
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
