<template>
    <div class="bidding">
      <banner :title="title" :desc="desc" />
      <bidding-list />
    </div>
</template>

<script>
import Banner from '@/views/portal/bidding/components/Banner'
import BiddingList from '@/views/portal/bidding/components/BiddingList'
export default {
  name: 'Bidding',
  components: {
    Banner,
    BiddingList,
  },
  data () {
    return {
      title: '服务公告',
      desc: '',
    }
  },
}
</script>

<style lang="scss" scoped>
.bidding{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
