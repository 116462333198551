<template>
    <div class="banner-container">
        <div class="item-container">
            <slot name="bg">
                <img class="item-bg" src="https://img.cdn.mg.xyz/mgcloud/photo/image/documents/documents-banner-bg.jpg" alt="" />
            </slot>
            <div class="item-text">
                <h3 class="item-title">{{title}}</h3>
                <p class="item-desc">{{desc}}</p>
                <div class="item-more" @click="$emit('more-click')" v-if="false">了解更多</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-container{
    width: 100%;
    height: 21.875vw;

    .item-container{
        position: relative;
        width: 100%;
        height: 100%;

        .item-img{
            position: absolute;
            top: 0;
            right: 18.75vw;
            z-index: 10;
            width: 44.375vw;
            height: 100%;
            object-fit: cover;
        }

        .item-bg{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 20;
            width: 100%;
            height: 100%;
        }

        .item-text{
            position: absolute;
            top: 50%;
            left: 18.75vw;
            z-index: 30;
            transform: translateY(-50%);

            .item-title{
                height: 2.5vw;
                color: #262626;
                font-weight: 500;
                font-size: 2.083vw;
                line-height: 2.5vw;
            }

            .item-desc{
                width: 31.25vw;
                height: 2.5vw;
                margin-top: 0.833vw;
                color: #646566;
                font-weight: normal;
                font-size: 0.833vw;
                line-height: 1.25vw;
                opacity: 0.5;
            }

             .item-more{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 5.625vw;
                height: 2.188vw;
                margin-top: 2.083vw;
                color: #FFF;
                font-size: 0.729vw;
                line-height: 1.146vw;
                text-align: center;
                border: 0.078vw solid #FFF;
                cursor: pointer;
            }

        }

    }

}
</style>
