var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dialog',{on:{"cancel":_vm.cancelDialog}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v("配置-包年包月套餐")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formItemLayout,false),[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('span',{staticClass:"info-title"},[_vm._v("基础服务计费项")])])],1),_vm._l((_vm.form.getFieldValue('basicKeys')),function(k,index){return [_c('a-form-item',{key:'basicKeysNames'+k,attrs:{"label":'计费项'+ (index+1) + '名称',"required":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `basicKeysNames[${k}]`,
              {
                validateTrigger: ['change', 'blur'],
                rules: [
                  { required: true, message: '请输入计费项名称'},
                  { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{0,20}$/, message: '仅支持中文、英文、数字；20字符以内' },
                ],
              },
            ]),expression:"[\n              `basicKeysNames[${k}]`,\n              {\n                validateTrigger: ['change', 'blur'],\n                rules: [\n                  { required: true, message: '请输入计费项名称'},\n                  { pattern: /^[\\u4e00-\\u9fa5a-zA-Z0-9]{0,20}$/, message: '仅支持中文、英文、数字；20字符以内' },\n                ],\n              },\n            ]"}],staticStyle:{"width":"80%","margin-right":"8px"},attrs:{"placeholder":"请输入计费项名称"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`basicKeysItemIds[${k}]`]),expression:"[`basicKeysItemIds[${k}]`]"}],attrs:{"type":"hidden"}}),(_vm.form.getFieldValue('basicKeys').length > 1)?_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"minus-circle-o","disabled":_vm.form.getFieldValue('basicKeys').length === 1},on:{"click":() => _vm.removeBasicKeys(k)}}):_vm._e()],1),_c('a-form-item',{key:'basicKeysDescs'+k,attrs:{"label":"套餐说明","required":true}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `basicKeysDescs[${k}]`,
              {
                validateTrigger: ['change', 'blur'],
                rules: [
                  { required: true, message: '请输入套餐说明' },
                  { max: 100, message: '100字符以内' },
                ],
              },
            ]),expression:"[\n              `basicKeysDescs[${k}]`,\n              {\n                validateTrigger: ['change', 'blur'],\n                rules: [\n                  { required: true, message: '请输入套餐说明' },\n                  { max: 100, message: '100字符以内' },\n                ],\n              },\n            ]"}],staticStyle:{"width":"80%","margin-right":"8px"},attrs:{"auto-size":{ minRows: 3, maxRows: 5 },"placeholder":"请输入套餐说明"}})],1)]}),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayoutWithOutLabel,false),[_c('a-button',{staticStyle:{"width":"80%"},attrs:{"type":"dashed"},on:{"click":_vm.addBasicKeys}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加计费项 ")],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('span',{staticClass:"info-title"},[_vm._v("增值服务计费项")])])],1),_vm._l((_vm.form.getFieldValue('extraKeys')),function(k,index){return [_c('a-form-item',{key:'extraKeysNames'+k,attrs:{"label":'计费项'+ (index+1) + '名称',"required":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `extraKeysNames[${k}]`,
              {
                validateTrigger: ['change', 'blur'],
                rules: [
                  { required: true, message: '请输入计费项名称'},
                  { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{0,20}$/, message: '仅支持中文、英文、数字；20字符以内' },
                ],
              },
            ]),expression:"[\n              `extraKeysNames[${k}]`,\n              {\n                validateTrigger: ['change', 'blur'],\n                rules: [\n                  { required: true, message: '请输入计费项名称'},\n                  { pattern: /^[\\u4e00-\\u9fa5a-zA-Z0-9]{0,20}$/, message: '仅支持中文、英文、数字；20字符以内' },\n                ],\n              },\n            ]"}],staticStyle:{"width":"80%","margin-right":"8px"},attrs:{"placeholder":"请输入计费项名称"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`extraKeysItemIds[${k}]`]),expression:"[`extraKeysItemIds[${k}]`]"}],attrs:{"type":"hidden"}}),_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"minus-circle-o"},on:{"click":() => _vm.removeExtraKeys(k)}})],1),_c('a-form-item',{key:'extraKeysDescs'+k,attrs:{"label":"套餐说明","required":true}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `extraKeysDescs[${k}]`,
              {
                validateTrigger: ['change', 'blur'],
                rules: [
                  { required: true, message: '请输入套餐说明' },
                  { max: 100, message: '100字符以内' },
                ],
              },
            ]),expression:"[\n              `extraKeysDescs[${k}]`,\n              {\n                validateTrigger: ['change', 'blur'],\n                rules: [\n                  { required: true, message: '请输入套餐说明' },\n                  { max: 100, message: '100字符以内' },\n                ],\n              },\n            ]"}],staticStyle:{"width":"80%","margin-right":"8px"},attrs:{"auto-size":{ minRows: 3, maxRows: 5 },"placeholder":"请输入套餐说明"}})],1)]}),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayoutWithOutLabel,false),[_c('a-button',{staticStyle:{"width":"80%"},attrs:{"type":"dashed"},on:{"click":_vm.addExtraKeys}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" 添加计费项 ")],1)],1)],2)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleConfirm}},[_vm._v("完成配置并启用")]),_c('a-button',{on:{"click":_vm.cancelDialog}},[_vm._v(_vm._s(_vm.$t('dialog.cancel')))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }