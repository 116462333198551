import Layout from '@/layouts/RouterView'
import store from '@/store'
import i18n from '@/locales'

const Dict = () => import(/* webpackChunkName: "system" */ /* webpackPrefetch: true */ '@System/views/dict/DictIndex')
const DictData = () => import(/* webpackChunkName: "system" */ /* webpackPrefetch: true */ '@System/views/dict/DictDataIndex')
const EipNetworkMapping = () => import(/* webpackChunkName: "system" */ /* webpackPrefetch: true */ '@System/views/eipnetworkmapping')
// const DictCreateOrUpdate = () => import(/* webpackChunkName: "system" */ /* webpackPrefetch: true */ '@System/views/dict/CreateOrUpdate')

export default {
  index: 2000,
  meta: {
    label: '系统设置',
    icon: 'menu-system',
  },
  menus: [
    {
      meta: {
        label: '系统设置',
      },
      submenus: [
        {
          path: '/dict',
          meta: {
            label: '字典管理',
            permission: 'dict_types_list',
            hidden: () => {
              if (store.getters.userInfo.name.toLowerCase() !== 'admin') {
                return true
              }
            },
          },
          component: Layout,
          children: [
            {
              name: 'Dict',
              path: '',
              meta: {},
              component: Dict,
            },
            {
              name: 'DictData',
              path: 'dict-data/:dictType',
              meta: {},
              component: DictData,
            },
            // {
            //   name: 'DictCreateOrUpdate',
            //   path: 'createOrUpdate',
            //   meta: {},
            //   component: DictCreateOrUpdate,
            // },
          ],
        },
        {
          path: '/eipnetworkmapping',
          meta: {
            label: i18n.t('dictionary.network_mapping'),
            // permission: 'dict_types_list',
            hidden: () => {
              if (store.getters.userInfo.name.toLowerCase() !== 'admin') {
                return true
              }
            },
          },
          component: Layout,
          children: [
            {
              name: 'EipNetworkMapping',
              path: '',
              meta: {},
              component: EipNetworkMapping,
            },
          ],
        },
      ],
    },
  ],
}
