<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">自定义计费项</div>
    <div slot="body">
      <a-form
        v-bind="formItemLayout"
        :form="form.fc">
        <a-form-item label="计费项名称">
          <a-input v-decorator="decorators.name" placeholder="请输入计费项名称" />
        </a-form-item>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'BillingItemCustomUpdateDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fd: {
          id: '',
        },
      },
      decorators: {
        name: ['name', {
          rules: [
            { required: true, message: '请输入计费项名称', trigger: 'blur' },
            { pattern: /^[\u4e00-\u9fa5a-zA-Z]{0,10}$/, message: '仅支持中文、英文，10字符以内' },
          ],
        }],
      },
      formItemLayout: {
        wrapperCol: {
          span: 18,
        },
        labelCol: {
          span: 4,
        },
      },
    }
  },
  created () {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail () {
      await this.$nextTick()
      this.form.fc.setFieldsValue({
        name: this.params.v.value,
      })
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.form.fc.validateFields()
        this.loading = true
        this.params.comfirm(values.name)
        this.loading = false
        this.cancelDialog()
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
