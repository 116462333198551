<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">新建类型</div>
    <div slot="body">
      <a-form
        v-bind="formItemLayout"
        :form="form.fc">
        <a-form-item label="类型名称">
          <a-input v-decorator="decorators.name" placeholder="请输入类型名称" />
        </a-form-item>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'CreateOrUpdateCloudProductTypesDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fd: {
          id: '',
        },
      },
      decorators: {
        name: ['name', {
          rules: [
            { required: true, message: '请输入类型名称', trigger: 'blur' },
            { pattern: /^[\u4e00-\u9fa5a-zA-Z]{0,10}$/, message: '仅支持中文、英文，10字符以内' },
          ],
        }],
      },
      formItemLayout: {
        wrapperCol: {
          span: 18,
        },
        labelCol: {
          span: 4,
        },
      },
    }
  },
  created () {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail () {
      if (!this.params.row.id) {
        this.form.fd = {
          id: '',
        }
        return
      }
      await this.$nextTick()
      this.form.fc.setFieldsValue({
        id: this.params.row.id,
        name: this.params.row.name,
      })
      this.form.fd.id = this.params.row.id
      // this.params.onManager('get', {
      //   managerArgs: {
      //     id: this.params.row.id,
      //   },
      // })
      //   .then(({ data }) => {
      //     console.log('data=', data)
      //     this.$nextTick(() => {
      //       this.form.fc.setFieldsValue({
      //         id: data.id,
      //         name: data.name,
      //       })
      //       this.form.fd.id = data.id
      //     })
      //   })
      //   .catch(() => {
      //     this.$message.error('获取详情失败')
      //     setTimeout(() => {
      //       this.cancelDialog()
      //     }, 1500)
      //   })
    },
    doCreate (data) {
      return this.params.onManager('create', {
        managerArgs: {
          data,
        },
      })
    },
    doUpdate (data) {
      return this.params.onManager('update', {
        id: this.form.fd.id,
        managerArgs: {
          data: { ...data, id: this.form.fd.id },
        },
      })
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.form.fc.validateFields()
        this.loading = true
        if (this.form.fd.id) {
          await this.doUpdate(values)
          // if (res.data.err_code !== 0) {
          //   this.$message.error(res.data.message || '更新失败')
          // }
        } else {
          await this.doCreate(values)
          // if (res.data.err_code !== 0) {
          //   this.$message.error(res.data.message || '创建失败')
          // }
        }
        this.loading = false
        this.cancelDialog()
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
