<template>
  <div class="bidding-list">
    <div class="breadcrumb-box">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item><a href="/">芒果云</a></a-breadcrumb-item>
        <a-breadcrumb-item>公告列表</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="table-box">
      <a-table class="bidding-table" :columns="columns" :data-source="tableData" :pagination="false" :showHeader="false" :customRow="customRow">
        <a slot="name" slot-scope="text, record" class="name-link" @click.prevent.stop="handleNameClick(record)">{{ text }}</a>
        <span slot="publicityf_at" slot-scope="text">{{ moment(text).format('YYYY-MM-DD HH:mm:ss') }}</span>
      </a-table>
      <a-pagination
        class="bidding-pagination"
        v-model="currentPage"
        :total="total"
        :pageSizeOptions="pageSizes"
        :page-size.sync="currentPagesize"
        @change="handleCurrentChange"
        @showSizeChange="handleSizeChange"
        show-quick-jumper />
    </div>
    <!-- <div class="tabs-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="全部">
        </a-tab-pane>
      </a-tabs>
    </div> -->
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/utils/http'
export default {
  name: 'BiddingList',
  data () {
    return {
      moment,
      columns: [
        {
          title: '信息名称',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
        },
        // {
        //   title: '所属地区',
        //   dataIndex: 'area',
        //   key: 'area',
        // },
        // {
        //   title: '来源',
        //   dataIndex: 'source',
        //   key: 'source',
        // },
        {
          title: '发布时间',
          dataIndex: 'publicityf_at',
          key: 'publicityf_at',
          align: 'right',
          scopedSlots: { customRender: 'publicityf_at' },
        },
        // {
        //   title: '投标截止时间',
        //   dataIndex: 'biddingEndTime',
        //   key: 'biddingEndTime',
        // },
      ],
      currentTitle: '全部信息',
      filterList: [
        { name: '全部信息', value: '0' },
        { name: '招标公告', value: '1' },
        { name: '中标公告', value: '2' },
        { name: '其他信息', value: '3' },
      ],
      activeIndex: 0,
      tableData: [],
      pageSizes: [20, 30, 50, 100],
      currentPage: 1,
      currentPagesize: 10,
      total: 0,
    }
  },
  mounted () {
    this.loadTableData()
  },
  methods: {
    loadTableData () {
      // 发送请求获取表格数据
      const params = {
        // offset: (this.currentPage - 1) * this.currentPagesize,
        // limit: this.currentPagesize,
        // filter: this.filterList.find(item => item.name === this.currentTitle)?.value,
        currentPage: this.currentPage,
        pageSize: this.currentPagesize,
      }
      http.get('/v1/bill-web/notice/list', { params: params })
        .then((response) => {
          const res = response.data
          console.log(res)
          this.tableData = res.data.list || []
          this.total = res.data.total
        })

      // const res = {
      //   data: {
      //     total: 1,
      //     list: [
      //       {
      //         id: 1,
      //         name: '芒果幻视2024年视频云直播转码服务项目公开甄选的公告',
      //         area: '湖南长沙',
      //         // source: '湖南芒果融创科技有限公司',
      //         source: '湖南芒果幻视科技有限公司',
      //         publishTime: '2024-03-15 12:00',
      //         biddingEndTime: '2024-03-25 12:00',
      //       },
      //     ],
      //   },
      // }
      // if (this.activeIndex > 1) {
      //   this.tableData = []
      //   this.total = 0
      //   return
      // }
      // this.tableData = res.data.list
      // this.total = res.data.total
    },
    handleFilter (item, index) {
      this.activeIndex = index
      this.currentTitle = item.name
      this.loadTableData()
    },
    customRow (record) {
      return {
        on: {
          click: () => {
            console.log('点击了行:', record)
            this.handleNameClick(record)
          },
        },
      }
    },
    handleNameClick (item) {
      console.log(item)
      this.$router.push({
        path: '/portal/biddingDetail',
        query: { id: item.id },
      })
    },
    handleSizeChange () {
      this.currentPage = 1
      this.loadTableData()
    },
    handleCurrentChange () {
      this.loadTableData()
    },
  },
}
</script>

<style lang="scss" scoped>
.bidding-list {
  display: flex;
  flex-direction: column;
  padding: 2.5vw 18.75vw;
  background: #fff;

  .breadcrumb-box{
    height: 1.458vw;
    display: flex;
    align-items: center;
    ::v-deep{
      .ant-breadcrumb a {
        color: #646566;
      }
      .ant-breadcrumb > span:last-child {
        color: #262626;
      }
    }
  }
  // .tabs-box{
  //   padding: 0.625vw 0;
  //   margin-top: 1.042vw;
  // }

  .title {
    height: 1.875vw;
    color: #222;
    font-weight: 500;
    font-size: 1.563vw;
    line-height: 1.875vw;
  }

  .table-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.875vw;

    .bidding-table {
      width: 100%;
      ::v-deep{
        .ant-table-tbody > tr > td {
          padding: 20px 24px;
        }
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
          background: rgba(40, 43, 51, 0.02) !important;
          cursor: pointer;
          .name-link{
            color: #0066FE;
          }
        }
      }
      .name-link{
        color: #262626;
        font-size: 0.833vw;
        font-weight: 500;
        &:hover{
          color: #0066FE;
        }
      }
    }

    .bidding-pagination {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 1.875vw;
      ::v-deep{
        .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
          border: none;
        }
        .ant-pagination-item{
          border-radius: 4px;
          border: 1px solid #DFE1E6;
        }
        .ant-pagination-item-active{
          border-color: #0066FE;
          background: #0066FE;
        }
        .ant-pagination-item-active a {
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
