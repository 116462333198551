<template>
  <base-dialog @cancel="cancelDialog" :width="1200">
    <div slot="header">配置-按量计费套餐</div>
    <div slot="body">
      <a-form
        v-bind="formItemLayout"
        :form="form">
        <a-row>
          <a-col :span="24">
            <span class="info-title">基础服务计费项</span>
          </a-col>
        </a-row>
        <template v-for="(k, index) in form.getFieldValue('basicKeys')">
          <a-form-item
            :key="'basicKeysNames'+k"
            :label="'计费项'+ (index+1)"
            :required="true">
            <a-radio-group v-decorator="[
                `basicKeysNames[${k}]`,
                {
                  initialValue: billingItemOpts[0].value,
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    { required: true, message: '请选择计费项名称'},
                  ],
                },
              ]"
              placeholder="请选择计费项名称"
              style="width: 80%; margin-right: 8px">
              <a-radio-button
                v-for="v in basicBillingItemOptsMap[k]"
                :key="v.value"
                :value="v.value"
                @click="handleBasicKeysNamesClick(k, v)">
                {{v.label}}
              </a-radio-button>
            </a-radio-group>
            <a-input type="hidden" v-decorator="[`basicKeysItemIds[${k}]`]" />
            <a-icon
              v-if="form.getFieldValue('basicKeys').length > 1"
              class="dynamic-delete-button"
              type="minus-circle-o"
              :disabled="form.getFieldValue('basicKeys').length === 1"
              @click="() => removeBasicKeys(k)" />
          </a-form-item>
          <a-form-item
            :key="'basicKeysModes'+k"
            label="计费模式"
            :required="true">
            <a-radio-group v-decorator="[
                `basicKeysModes[${k}]`,
                {
                  initialValue: billingModeOpts[0].value,
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    { required: true, message: '请选择计费模式'},
                  ],
                },
              ]"
              placeholder="请选择计费模式"
              @change="handleBasicKeysModesChange(k, $event)"
              style="width: 80%; margin-right: 8px">
              <a-radio-button
                v-for="v in billingModeOpts"
                :key="v.value"
                :value="v.value">{{v.label}}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :key="'basicKeysTables'+k"
            label="计费表"
            :required="true">
            <!-- 计费表 -->
            <div>
              <a-table bordered :data-source="basicDataSourceMap[k]" :columns="basicColumnsMap[k]" :pagination="false">
                <template slot="name" slot-scope="text, record">
                  <a-input :value="text" @change="handleBasicKeysTablesCellChange(k, record.key, 'name', $event.target.value)" />
                </template>
                <template slot="number" slot-scope="text, record">
                  <a-input-number :value="text" @change="handleBasicKeysTablesCellChange(k, record.key, 'number', $event)" />
                </template>
                <template slot="ladder" slot-scope="text, record">
                  <div class="d-flex align-items-center">
                    <a-input-number :value="record.min" @change="handleBasicKeysTablesCellChange(k, record.key, 'min', $event)" style="width:120px" />
                    -
                    <a-input-number :value="record.max" @change="handleBasicKeysTablesCellChange(k, record.key, 'max', $event)" style="width:120px" />
                  </div>
                </template>
                <template slot="unit" slot-scope="text, record">
                  <a-select :value="text" :options="unitOpts" @change="handleBasicKeysTablesCellChange(k, record.key, 'unit', $event)" />
                </template>
                <template slot="billing_unit" slot-scope="text, record">
                  <a-select :value="text" :options="billingUnitOpts" @change="handleBasicKeysTablesCellChange(k, record.key, 'billing_unit', $event)" />
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-popconfirm
                    v-if="basicColumnsMap[k].length"
                    :disabled="basicDataSourceMap[k].length === 1"
                    title="确认删除?"
                    @confirm="() => onBasicKeysTablesDelete(k, record.key)">
                    <a-button type="link" :disabled="basicDataSourceMap[k].length === 1">
                      删除
                    </a-button>
                  </a-popconfirm>
                </template>
              </a-table>
              <a-button class="editable-add-btn" @click="handleBasicKeysTablesAdd(k)">
                添加
              </a-button>
            </div>
          </a-form-item>
        </template>
        <a-form-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 80%" @click="addBasicKeys">
            <a-icon type="plus" /> 添加计费项
          </a-button>
        </a-form-item>
        <a-row>
          <a-col :span="24">
            <span class="info-title">增值服务计费项</span>
          </a-col>
        </a-row>
        <template v-for="(k, index) in form.getFieldValue('extraKeys')">
          <a-form-item
            :key="'extraKeysNames'+k"
            :label="'计费项'+ (index+1)"
            :required="true">
            <a-input
              v-decorator="[
                `extraKeysNames[${k}]`,
                {
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    { required: true, message: '请输入计费项名称'},
                    { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{0,10}$/, message: '仅支持中文、英文、数字；10字符以内' },
                  ],
                },
              ]"
              placeholder="请输入计费项名称"
              style="width: 80%; margin-right: 8px" />
            <a-input type="hidden" v-decorator="[`extraKeysItemIds[${k}]`]" />
            <a-icon
              class="dynamic-delete-button"
              type="minus-circle-o"
              @click="() => removeExtraKeys(k)" />
          </a-form-item>
          <a-form-item
            :key="'extraKeysModes'+k"
            label="计费模式"
            :required="true">
            <a-radio-group v-decorator="[
                `extraKeysModes[${k}]`,
                {
                  initialValue: billingModeOpts[0].value,
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    { required: true, message: '请选择计费模式'},
                  ],
                },
              ]"
              placeholder="请选择计费模式"
              @change="handleExtraKeysModesChange(k, $event)"
              style="width: 80%; margin-right: 8px">
              <a-radio-button
                v-for="v in billingModeOpts"
                :key="v.value"
                :value="v.value">{{v.label}}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :key="'extraKeysTables'+k"
            label="计费表"
            :required="true">
            <!-- 增值服务计费表 -->
            <div>
              <a-table bordered :data-source="extraDataSourceMap[k]" :columns="extraColumnsMap[k]" :pagination="false">
                <template slot="name" slot-scope="text, record">
                  <a-input :value="text" @change="handleExtraKeysTablesCellChange(k, record.key, 'name', $event.target.value)" />
                </template>
                <template slot="number" slot-scope="text, record">
                  <a-input-number :value="text" @change="handleExtraKeysTablesCellChange(k, record.key, 'number', $event)" />
                </template>
                <template slot="ladder" slot-scope="text, record">
                  <div class="d-flex align-items-center">
                    <a-input-number :value="record.min" @change="handleExtraKeysTablesCellChange(k, record.key, 'min', $event)" style="width:120px" />
                    -
                    <a-input-number :value="record.max" @change="handleExtraKeysTablesCellChange(k, record.key, 'max', $event)" style="width:120px" />
                  </div>
                </template>
                <template slot="unit" slot-scope="text, record">
                  <a-select :value="text" :options="unitOpts" @change="handleExtraKeysTablesCellChange(k, record.key, 'unit', $event)" />
                </template>
                <template slot="billing_unit" slot-scope="text, record">
                  <a-select :value="text" :options="billingUnitOpts" @change="handleExtraKeysTablesCellChange(k, record.key, 'billing_unit', $event)" />
                </template>
                <template slot="operation" slot-scope="text, record">
                  <a-popconfirm
                    v-if="extraColumnsMap[k].length"
                    :disabled="extraDataSourceMap[k].length === 1"
                    title="确认删除?"
                    @confirm="() => onExtraKeysTablesDelete(k, record.key)">
                    <a-button type="link" :disabled="extraDataSourceMap[k].length === 1">
                      删除
                    </a-button>
                  </a-popconfirm>
                </template>
              </a-table>
              <a-button class="editable-add-btn" @click="handleExtraKeysTablesAdd(k)">
                添加
              </a-button>
            </div>
          </a-form-item>
        </template>
        <a-form-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 80%" @click="addExtraKeys">
            <a-icon type="plus" /> 添加计费项
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">完成配置并启用</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import _ from 'lodash'
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import { maxBillingItem, maxBillingTableItem, chineseNums, billingItemOpts, billingModeOpts, unitOpts, billingUnitOpts } from '../constants'

export default {
  name: 'CloudProductPostpaidConfigDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },
      basicBillingItemOptsMap: {},
      extraBillingItemOptsMap: {},
      // 流量、公网带宽、带宽峰值、容量、请求次数
      billingItemOpts,
      // 1-阶梯计费 2-常规计费
      billingModeOpts,
      basicBillingModeMap: {},
      basicDataSourceMap: {},
      basicColumnsMap: {},
      extraBillingModeMap: {},
      extraDataSourceMap: {},
      extraColumnsMap: {},
      // GB、Mbps、次、万次、个、张、分钟
      // unitOpts,
      // /GB、/Mbps/天、/Mbps/小时、/次、/万次、/个、/张、/分钟
      // billingUnitOpts,
      // 阶梯计费列
      ladderColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          customRender: (text, record, index) => {
            return index + 1
          },
        },
        {
          title: '名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '阶梯',
          dataIndex: 'min',
          scopedSlots: { customRender: 'ladder' },
        },
        {
          title: '阶梯单位',
          dataIndex: 'unit',
          scopedSlots: { customRender: 'unit' },
        },
        {
          title: '计费单位',
          dataIndex: 'billing_unit',
          scopedSlots: { customRender: 'billing_unit' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      // 常规计费列
      commonColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          customRender: (text, record, index) => {
            return index + 1
          },
        },
        {
          title: '名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '常规数量',
          dataIndex: 'number',
          scopedSlots: { customRender: 'number' },
        },
        {
          title: '单位',
          dataIndex: 'unit',
          scopedSlots: { customRender: 'unit' },
        },
        {
          title: '计费单位',
          dataIndex: 'billing_unit',
          scopedSlots: { customRender: 'billing_unit' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      basicKey: 0,
      extraKey: 0,
    }
  },
  computed: {
    unitOpts () {
      const unitDict = this.getDictDatas(this.DICT_TYPE.UNIT)
      return [...unitOpts, ...unitDict]
    },
    billingUnitOpts () {
      const billingUnitDict = this.getDictDatas(this.DICT_TYPE.BILLING_UNIT)
      return [...billingUnitOpts, ...billingUnitDict]
    },
  },
  beforeCreate () {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('basicKeys', { initialValue: [], preserve: true })
    this.form.getFieldDecorator('extraKeys', { initialValue: [], preserve: true })
  },
  created () {
    this.fetchDetail()
  },
  methods: {
    fetchDetail () {
      this.$http.get('/v2/cloud_product_postpaid_items/config', {
        params: {
          product_id: this.params.row.product_id,
        },
      }).then(res => {
        console.log('res.data:', res.data)
        // if (res.data.err_code !== 0) {
        //   this.$message.error(res.data.message)
        //   return
        // }
        if (res.data) {
          const { form } = this
          const items = res.data.items || []
          const basicKeysItems = items.filter(item => item.billing_item_type === 1).sort((a, b) => a.seq - b.seq)
          if (basicKeysItems.length) {
            // 先更新basicKeys，再更新basicKeysNames
            const basicKeys = form.getFieldValue('basicKeys')
            basicKeysItems.forEach((item, index) => {
              basicKeys.push(this.basicKey++)
            })
            form.setFieldsValue({
              basicKeys,
            })
            this.$nextTick(() => {
              basicKeysItems.forEach((item, index) => {
                const k = index
                this.$set(this.basicBillingItemOptsMap, k, [])
                // 如果计费项名称不存在则需要修改
                const tempOpts = _.cloneDeep(billingItemOpts)
                const tempBillingItem = tempOpts.find(o => o.value === item.billing_item)
                if (!tempBillingItem) {
                  tempOpts[tempOpts.length - 1].label = item.billing_item
                  tempOpts[tempOpts.length - 1].value = item.billing_item
                }
                this.basicBillingItemOptsMap[k] = tempOpts
                // 给this.basicDataSourceMap添加dataSource,默认值为[]
                this.$set(this.basicDataSourceMap, k, [])
                this.$set(this.basicColumnsMap, k, [])
                this.$set(this.basicBillingModeMap, k, item.billing_mode)
                // 根据计费模式动态切换列
                this.basicColumnsMap[k] = item.billing_mode === 1 ? this.ladderColumns : this.commonColumns
                const dataSource = []
                item.rows.forEach((row, i) => {
                  const newData = {
                    key: i,
                    row_id: row.row_id,
                    name: row.name,
                    min: row.min,
                    max: row.max,
                    number: row.number,
                    unit: row.unit,
                    billing_unit: row.billing_unit,
                  }
                  dataSource.push(newData)
                })
                this.basicDataSourceMap[k] = [...dataSource]
              })
              form.setFieldsValue({
                basicKeysItemIds: basicKeysItems.map(item => item.item_id),
                basicKeysNames: basicKeysItems.map(item => item.billing_item),
                basicKeysModes: basicKeysItems.map(item => item.billing_mode),
              })
            })
          } else {
            // 默认添加一个基础计费项
            this.addBasicKeys()
          }
          const extraKeysItems = items.filter(item => item.billing_item_type === 2).sort((a, b) => a.seq - b.seq)
          if (extraKeysItems.length) {
            // 先更新extraKeys，再更新extraKeysNames和extraKeysDescs
            const extraKeys = form.getFieldValue('extraKeys')
            extraKeysItems.forEach((item, index) => {
              extraKeys.push(this.extraKey++)
            })
            form.setFieldsValue({
              extraKeys,
            })
            this.$nextTick(() => {
              extraKeysItems.forEach((item, index) => {
                const k = index
                // 给this.basicDataSourceMap添加dataSource,默认值为[]
                this.$set(this.extraDataSourceMap, k, [])
                this.$set(this.extraColumnsMap, k, [])
                this.$set(this.extraBillingModeMap, k, item.billing_mode)
                // 根据计费模式动态切换列
                this.extraColumnsMap[k] = item.billing_mode === 1 ? this.ladderColumns : this.commonColumns
                const dataSource = []
                item.rows.forEach((row, i) => {
                  const newData = {
                    key: i,
                    row_id: row.row_id,
                    name: row.name,
                    min: row.min,
                    max: row.max,
                    number: row.number,
                    unit: row.unit,
                    billing_unit: row.billing_unit,
                  }
                  dataSource.push(newData)
                })
                this.extraDataSourceMap[k] = [...dataSource]
              })
              form.setFieldsValue({
                extraKeysItemIds: extraKeysItems.map(item => item.item_id),
                extraKeysNames: extraKeysItems.map(item => item.billing_item),
                extraKeysModes: extraKeysItems.map(item => item.billing_mode),
              })
            })
          }
        }
      })
    },
    handleBasicKeysNamesClick (k, v) {
      console.log('k:', k, 'v:', v)
      const basicKeysModes = this.form.getFieldValue('basicKeysModes')
      if (v.custom) {
        const basicKeysNames = this.form.getFieldValue('basicKeysNames')
        // console.log('自定义', basicKeysNames)
        this.createDialog('BillingItemCustomUpdateDialog', {
          k,
          v,
          comfirm: (value) => {
            v.label = value
            v.value = value
            basicKeysNames[k] = value
            this.form.setFieldsValue({
              basicKeysNames: basicKeysNames,
            })
            if (basicKeysModes[k] === 2) {
              this.basicDataSourceMap[k].forEach(item => {
                item.name = v.value
              })
            }
          },
        })
      } else {
        if (basicKeysModes[k] === 2) {
          this.basicDataSourceMap[k].forEach(item => {
            item.name = v.value
          })
        }
      }
    },
    removeBasicKeys (k) {
      const { form } = this
      const basicKeys = form.getFieldValue('basicKeys')
      if (basicKeys.length === 1) {
        return
      }
      form.setFieldsValue({
        basicKeys: basicKeys.filter(key => key !== k),
      })
    },
    addBasicKeys () {
      const { form } = this
      const basicKeys = form.getFieldValue('basicKeys')
      if (basicKeys.length >= maxBillingItem) {
        this.$message.error(`最多添加${maxBillingItem}个计费项`)
        return
      }
      const nextKeys = basicKeys.concat(this.basicKey++)
      form.setFieldsValue({
        basicKeys: nextKeys,
      })
      // 补充计费表
      const k = this.basicKey - 1
      // 给this.basicDataSourceMap添加dataSource,默认值为[]
      this.$set(this.basicDataSourceMap, k, [])
      this.$set(this.basicColumnsMap, k, [])
      this.$set(this.basicBillingModeMap, k, 1)
      // 根据计费模式动态切换列
      this.basicColumnsMap[k] = this.ladderColumns
      this.$set(this.basicBillingItemOptsMap, k, [])
      this.basicBillingItemOptsMap[k] = _.cloneDeep(billingItemOpts)
      this.handleBasicKeysTablesAdd(k)
    },
    handleBasicKeysTablesAdd (k) {
      const dataSource = this.basicDataSourceMap[k]
      if (dataSource.length >= maxBillingTableItem) {
        this.$message.error(`最多添加${maxBillingTableItem}个计费表项`)
        return
      }
      const maxKeyObj = dataSource.reduce((prev, current) => {
        return prev.key > current.key ? prev : current
      }, { key: 0 })
      // console.log('maxKeyObj=', maxKeyObj)

      const basicKeysModes = this.form.getFieldValue('basicKeysModes')
      let name = `第${chineseNums[dataSource.length + 1]}档`
      if (basicKeysModes && basicKeysModes[k] === 2) {
        const basicKeysNames = this.form.getFieldValue('basicKeysNames')
        name = basicKeysNames[k]
      }
      const newData = {
        key: maxKeyObj.key + 1,
        row_id: undefined,
        name,
        min: 0,
        max: 0,
        number: 0,
        unit: '',
        billing_unit: '',
      }
      this.basicDataSourceMap[k] = [...dataSource, newData]
    },
    onBasicKeysTablesDelete (k, key) {
      const dataSource = [...this.basicDataSourceMap[k]]
      this.basicDataSourceMap[k] = dataSource.filter(item => item.key !== key)
      const basicKeysModes = this.form.getFieldValue('basicKeysModes')
      if (basicKeysModes[k] === 1) {
        this.basicDataSourceMap[k].forEach((item, index) => {
          if (!item.row_id) {
            item.name = `第${chineseNums[index + 1]}档`
          }
        })
      }
    },
    handleBasicKeysTablesCellChange (k, key, dataIndex, value) {
      // console.log('handleBasicKeysTablesCellChange', k, key, dataIndex, value)
      const dataSource = [...this.basicDataSourceMap[k]]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.basicDataSourceMap[k] = dataSource
      }
    },
    handleBasicKeysModesChange (k, e) {
      if (e.target.value === 1) {
        this.basicColumnsMap[k] = this.ladderColumns
        this.basicDataSourceMap[k].forEach((item, index) => {
          item.name = `第${chineseNums[index + 1]}档`
        })
      } else {
        this.basicColumnsMap[k] = this.commonColumns
        const basicKeysNames = this.form.getFieldValue('basicKeysNames')
        this.basicDataSourceMap[k].forEach(item => {
          item.name = basicKeysNames[k]
        })
      }
      this.basicBillingModeMap[k] = e.target.value
      // this.basicColumnsMap[k] = e.target.value === 1 ? this.ladderColumns : this.commonColumns
    },
    removeExtraKeys (k) {
      const { form } = this
      const extraKeys = form.getFieldValue('extraKeys')
      form.setFieldsValue({
        extraKeys: extraKeys.filter(key => key !== k),
      })
    },
    addExtraKeys () {
      const { form } = this
      const extraKeys = form.getFieldValue('extraKeys')
      if (extraKeys.length >= maxBillingItem) {
        this.$message.error(`最多添加${maxBillingItem}个计费项`)
        return
      }
      const nextKeys = extraKeys.concat(this.extraKey++)
      form.setFieldsValue({
        extraKeys: nextKeys,
      })
      // 补充计费表
      const k = this.extraKey - 1
      // 给this.extraDataSourceMap添加dataSource,默认值为[]
      this.$set(this.extraDataSourceMap, k, [])
      this.$set(this.extraColumnsMap, k, [])
      this.$set(this.extraBillingModeMap, k, 1)
      // 根据计费模式动态切换列
      this.extraColumnsMap[k] = this.ladderColumns
      // this.$set(this.extraBillingItemOptsMap, k, [])
      // this.extraBillingItemOptsMap[k] = _.cloneDeep(billingItemOpts)
      this.handleExtraKeysTablesAdd(k)
    },
    handleExtraKeysTablesAdd (k) {
      const dataSource = this.extraDataSourceMap[k]
      if (dataSource.length >= maxBillingTableItem) {
        this.$message.error(`最多添加${maxBillingTableItem}个计费表项`)
        return
      }
      const maxKeyObj = dataSource.reduce((prev, current) => {
        return prev.key > current.key ? prev : current
      }, { key: 0 })
      const extraKeysModes = this.form.getFieldValue('extraKeysModes')
      let name = `第${chineseNums[dataSource.length + 1]}档`
      if (extraKeysModes && extraKeysModes[k] === 2) {
        const extraKeysNames = this.form.getFieldValue('extraKeysNames')
        name = extraKeysNames[k]
      }
      const newData = {
        key: maxKeyObj.key + 1,
        row_id: undefined,
        name,
        min: 0,
        max: 0,
        number: 0,
        unit: '',
        billing_unit: '',
      }
      this.extraDataSourceMap[k] = [...dataSource, newData]
    },
    onExtraKeysTablesDelete (k, key) {
      const dataSource = [...this.extraDataSourceMap[k]]
      this.extraDataSourceMap[k] = dataSource.filter(item => item.key !== key)
      const extraKeysModes = this.form.getFieldValue('extraKeysModes')
      if (extraKeysModes[k] === 1) {
        this.extraDataSourceMap[k].forEach((item, index) => {
          if (!item.row_id) {
            item.name = `第${chineseNums[index + 1]}档`
          }
        })
      }
    },
    handleExtraKeysTablesCellChange (k, key, dataIndex, value) {
      // console.log('handleBasicKeysTablesCellChange', k, key, dataIndex, value)
      const dataSource = [...this.extraDataSourceMap[k]]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.extraDataSourceMap[k] = dataSource
      }
    },
    handleExtraKeysModesChange (k, e) {
      if (e.target.value === 1) {
        this.extraColumnsMap[k] = this.ladderColumns
        this.extraDataSourceMap[k].forEach((item, index) => {
          item.name = `第${chineseNums[index + 1]}档`
        })
      } else {
        this.extraColumnsMap[k] = this.commonColumns
        const extraKeysNames = this.form.getFieldValue('extraKeysNames')
        this.extraDataSourceMap[k].forEach(item => {
          item.name = extraKeysNames[k]
        })
      }
      this.extraBillingModeMap[k] = e.target.value
      // this.extraColumnsMap[k] = e.target.value === 1 ? this.ladderColumns : this.commonColumns
    },
    doConfig (data) {
      return this.$http.post('/v2/cloud_product_postpaid_items/config', data)
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.form.validateFields()
        const { basicKeys, basicKeysItemIds, basicKeysNames, basicKeysModes, extraKeys, extraKeysItemIds, extraKeysNames, extraKeysModes } = values
        console.log('basicKeys: ', basicKeys, basicKeysItemIds, basicKeysNames, basicKeysModes, this.basicDataSourceMap)
        console.log('extraKeys: ', extraKeys, extraKeysItemIds, extraKeysNames, extraKeysModes, this.extraDataSourceMap)
        const items = []
        basicKeys.forEach(key => {
          items.push({
            item_id: basicKeysItemIds[key],
            billing_item: basicKeysNames[key],
            billing_mode: basicKeysModes[key],
            billing_item_type: 1,
            rows: this.basicDataSourceMap[key].map(row => {
              return {
                ...row,
                min: row.min || 0,
                max: row.max || 0,
                number: row.number || 0,
              }
            }),
          })
        })
        extraKeys.forEach(key => {
          items.push({
            item_id: extraKeysItemIds[key],
            billing_item: extraKeysNames[key],
            billing_mode: extraKeysModes[key],
            billing_item_type: 2,
            rows: this.extraDataSourceMap[key].map(row => {
              return {
                ...row,
                min: row.min || 0,
                max: row.max || 0,
                number: row.number || 0,
              }
            }),
          })
        })
        const data = {
          product_id: this.params.row.product_id,
          items,
        }
        console.log('data: ', data)

        this.loading = true
        await this.doConfig(data)
        // if (res.data.err_code !== 0) {
        //   this.$message.error(res.data.message)
        //   this.loading = false
        //   return
        // }
        this.$message.success('配置成功')
        this.loading = false
        this.params.refresh()
        this.cancelDialog()
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
<style>
.info-title{
  font-size: 15px;
  font-weight: 500;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
