<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">新建云产品</div>
    <div slot="body">
      <a-form
        v-bind="formItemLayout"
        :form="form.fc">
        <a-form-item label="类型">
          <a-radio-group v-decorator="decorators.type_id" placeholder="请选择类型" :disabled="isUpdate">
            <a-radio-button
              v-for="v in cloudProductTypeOpts"
              :key="v.value"
              :value="v.value">{{v.label}}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="名称">
          <a-input v-decorator="decorators.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="计费方式">
          <a-checkbox-group
            v-decorator="decorators.billing_type"
            placeholder="请输入计费方式"
            :options="billingTypeOpts"
            :disabled="isUpdate" />
        </a-form-item>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">{{ $t('dialog.ok') }}</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'

export default {
  name: 'CreateOrUpdateCloudProductsDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      cloudProductTypeOpts: [],
      billingTypeOpts: [{
        label: '包年包月套餐',
        value: 'prepaid',
      }, {
        label: '按量计费',
        value: 'postpaid',
      }],
      loading: false,
      form: {
        fc: this.$form.createForm(this),
        fd: {
          id: '',
          product_id: '',
        },
      },
      decorators: {
        type_id: ['type_id', {
          rules: [
            { required: true, message: '请选择类型' },
          ],
        }],
        name: ['name', {
          rules: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            { pattern: /^([\u4e00-\u9fa5a-zA-Z()（）\s]{0,20})$/, message: '仅支持支持中文、英文、（）、空格符，20字符以内' },
          ],
        }],
        billing_type: ['billing_type', {
          rules: [
            { required: true, message: '请选择计费方式' },
          ],
        }],
      },
      formItemLayout: {
        wrapperCol: {
          span: 18,
        },
        labelCol: {
          span: 4,
        },
      },
    }
  },
  computed: {
    isUpdate () {
      return !!this.params.row.id
    },
  },
  created () {
    this.loadCloudProductTypes()
    this.fetchDetail()
  },
  methods: {
    async loadCloudProductTypes () {
      const response = await new this.$Manager('cloud_product_types', 'v2').list({
        params: {
          limit: 1000,
        },
      })
      const data = response.data
      this.cloudProductTypeOpts = data.data.map((item) => {
        return {
          label: item.name,
          value: item.type_id,
        }
      })
      if (this.cloudProductTypeOpts.length) {
        this.form.fc.setFieldsValue({
          type_id: this.cloudProductTypeOpts[0].value,
        })
      }
    },
    async fetchDetail () {
      if (!this.params.row.id) {
        this.form.fd = {
          id: '',
        }
        return
      }
      await this.$nextTick()
      this.form.fc.setFieldsValue({
        id: this.params.row.id,
        name: this.params.row.name,
        type_id: this.params.row.type_id,
        billing_type: this.params.row.billing_type,
      })
      this.form.fd.id = this.params.row.id
      this.form.fd.product_id = this.params.row.product_id
    },
    doCreate (data) {
      return this.$http.post('/v2/cloud_products/save', data)
    },
    doUpdate (data) {
      return this.$http.post(`/v2/cloud_products/${this.form.fd.id}/update-name`, { name: data.name })
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.form.fc.validateFields()
        this.loading = true
        if (this.isUpdate) {
          await this.doUpdate(values)
        } else {
          await this.doCreate(values)
          // if (res.data.err_code !== 0) {
          //   this.$message.error(res.data.message || '创建失败')
          // }
        }
        this.params.refresh()
        this.loading = false
        this.cancelDialog()
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
