<template>
    <div class="documents">
      <banner title="计费规则" desc="帮您全面了解芒果云产品价格，为您预估上云成本提供帮助">
        <img class="item-bg" src="https://img.cdn.mg.xyz/mgcloud/photo/image/documents/documents-banner-billing-bg.jpg" alt="" slot="bg" />
      </banner>
      <doc-menu :menus="menus" />
      <!-- <product-billing-rules :menus="menus" /> -->
    </div>
</template>

<script>
import { productCategoryMenu2 } from '@/maps/common'
import Banner from '@/views/portal/documents/components/Banner'
import DocMenu from '@/views/portal/documents/components/DocMenu'
// import ProductBillingRules from '@/views/portal/documents/components/ProductBillingRules'
export default {
  name: 'Billing',
  components: {
    Banner,
    DocMenu,
    // ProductBillingRules,
  },
  data () {
    return {
      menus: productCategoryMenu2,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
