export const TOKEN_KEY = 'portal_token'
export const SINGLE_TOKEN_KEY = 'cross_web_ticket' // 用来设置给幻城系统其他平台单点登录的token的标识
export const SINGLE_UUID_KEY = 'cross_web_uuid' // 用来设置给幻城系统其他平台单点登录的uuid的标识

export const httpCodeMap = {
  success: 0, // 成功
}
// 服务与支持 菜单
export const supportMenus = [
  {
    name: '文档中心',
    id: 1,
    children: [
      {
        id: 11,
        name: '文档中心',
        url: '/portal/documents',
      },
      // {
      //   id: 12,
      //   name: '新手入门',
      //   url: '/documentsDetail?space=7d797153873548e7bb2fa54050eed48b',
      // },
    ],
  },
  {
    name: '定价说明',
    id: 2,
    children: [
      {
        id: 21,
        name: '计费规则',
        url: '/portal/billing',
        is_hot: false,
      },
    ],
  },
  {
    name: '服务支持',
    id: 3,
    children: [
      // {
      //   id: 31,
      //   name: '建议反馈',
      //   url: '/',
      // },
      {
        id: 32,
        name: '联系我们',
        url: '/portal/contactus',
      },
    ],
  },
]

// 热门产品 菜单
export const hotProductMenus = [
  {
    name: '媒体服务',
    id: 20,
    is_open: 2,
    parent_id: 0,
    is_hot: 0,
    is_new: 0,
    children: [
      {
        name: '云渲染',
        id: 24,
        is_open: 2,
        parent_id: 2,
        is_hot: 1,
        is_new: 0,
      },
      {
        name: '云直播',
        id: 25,
        is_open: 2,
        parent_id: 2,
        is_hot: 1,
        is_new: 0,
      },
      {
        name: 'MCDN',
        id: 26,
        is_open: 2,
        parent_id: 2,
        is_hot: 1,
        is_new: 0,
      },
    ],
  },
  {
    name: '人工智能',
    id: 30,
    is_open: 2,
    parent_id: 0,
    is_hot: 0,
    is_new: 0,
    children: [
      {
        name: 'GPU云服务器',
        id: 27,
        is_open: 2,
        parent_id: 2,
        is_hot: 1,
        is_new: 0,
        developing: 0, // 0: 正常 1: 开发中
      },
    ],
  },
  {
    name: '计算',
    id: 4,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '云服务器ECS',
      id: 9,
      is_open: 2,
      parent_id: 4,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    }],
  }, {
    name: '存储',
    id: 5,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '硬盘',
      id: 11,
      is_open: 2,
      parent_id: 5,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    }],
  }, {
    name: '网络',
    id: 6,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '弹性公网IP',
      id: 19,
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    {
      name: '负载均衡SLB',
      id: 21,
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    {
      name: '专有网络VPC',
      id: 22,
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    {
      name: 'NAT网关',
      id: 23,
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    ],
  },
]

// 媒体服务 菜单
export const mediaProductMenus = [
  {
    name: '云渲染',
    id: 24,
    is_open: 2,
    parent_id: 2,
    is_hot: 1,
    is_new: 0,
  },
  {
    name: '云直播',
    id: 25,
    is_open: 2,
    parent_id: 2,
    is_hot: 1,
    is_new: 0,
  },
  {
    name: 'MCDN',
    id: 26,
    is_open: 2,
    parent_id: 2,
    is_hot: 1,
    is_new: 0,
  },
]

// 人工智能 菜单
export const aiProductMenus = [
  {
    name: 'GPU云服务器',
    id: 27,
    is_open: 2,
    parent_id: 4,
    is_hot: 1,
    is_new: 0,
    developing: 0, // 0: 正常 1: 开发中
  },
]

// 云计算基础 菜单
export const cloudProductMenus = [
  {
    name: '计算',
    id: 4,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '云服务器ECS',
      id: 9,
      is_open: 2,
      parent_id: 4,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    }],
  }, {
    name: '存储',
    id: 5,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '硬盘',
      id: 11,
      is_open: 2,
      parent_id: 5,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    }],
  }, {
    name: '网络',
    id: 6,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '弹性公网IP',
      id: 19,
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    {
      name: '负载均衡SLB',
      id: 21,
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    {
      name: '专有网络VPC',
      id: 22,
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    {
      name: 'NAT网关',
      id: 23,
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    ],
  },
]

// 文档中心 菜单
export const productCategoryMenu = [
  {
    name: '计算',
    icon: 'document-icon-jisuan',
    id: 4,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '云服务器ECS',
      desc: '弹性计算服务',
      id: 9,
      pageId: 6,
      space: '1b1d44ea04a74ece9863e2330420469e',
      is_open: 2,
      parent_id: 4,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    }],
  }, {
    name: '存储',
    icon: 'document-icon-cunchu',
    id: 5,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '硬盘',
      id: 11,
      pageId: 8,
      space: 'cc8fb85d5a6541d8ac5e610e7cbb2e21',
      is_open: 2,
      parent_id: 5,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    }],
  }, {
    name: '网络',
    icon: 'document-icon-wangluo',
    id: 6,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '弹性公网IP',
      id: 19,
      pageId: 11,
      space: 'd1c6c139bdbd49eb866f1b86de1fdceb',
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    {
      name: '负载均衡SLB',
      id: 21,
      pageId: 12,
      space: '378805a9083e47e1b0b1f966f0472d72',
      is_open: 2,
      parent_id: 6,
      is_hot: 0,
      is_new: 1,
      child_tree: null,
    },
    {
      name: '专有网络VPC',
      id: 22,
      pageId: 13,
      space: '46941ca098104ea5b9ae4e58cabf8272',
      is_open: 2,
      parent_id: 6,
      is_hot: 0,
      is_new: 0,
      child_tree: null,
    },
    {
      name: 'NAT网关',
      id: 23,
      pageId: 14,
      space: '1ef7f8b24d7e497fa9bce9ea822959a4',
      is_open: 2,
      parent_id: 6,
      is_hot: 0,
      is_new: 0,
      child_tree: null,
    },
    ],
  },
  {
    name: '媒体服务',
    icon: 'document-icon-meitifuwu',
    id: 7,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [
      {
        name: '云渲染',
        id: 24,
        pageId: 241,
        space: '261071476259429494735ff8a94617cc',
        is_open: 2,
        parent_id: 7,
        is_hot: 1,
        is_new: 0,
        child_tree: null,
      },
      {
        name: '云直播',
        id: 25,
        pageId: 242,
        space: '7e12b549f6524f0ba633d8f1458560c5',
        is_open: 2,
        parent_id: 7,
        is_hot: 1,
        is_new: 0,
        child_tree: null,
      },
      {
        name: 'MCDN',
        id: 26,
        pageId: 243,
        space: 'a77841d1cda249ad9e0f841d47303517',
        is_open: 2,
        parent_id: 7,
        is_hot: 1,
        is_new: 0,
        child_tree: null,
      },
    ],
  },
]

// 定价计费规则 菜单
export const productCategoryMenu2 = [
  {
    name: '计算',
    icon: 'document-icon-jisuan',
    id: 4,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '云服务器ECS',
      desc: '弹性计算服务',
      id: 9,
      pageId: 106,
      space: 'c69b58b0e44b4167b065bddbe5be1dab',
      is_open: 2,
      parent_id: 4,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    }],
  }, {
    name: '存储',
    icon: 'document-icon-cunchu',
    id: 5,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '硬盘',
      id: 11,
      pageId: 108,
      space: '949d86a028474bc3bca3d4f2e8ca635d',
      is_open: 2,
      parent_id: 5,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    }],
  }, {
    name: '网络',
    icon: 'document-icon-wangluo',
    id: 6,
    is_open: 2,
    parent_id: 3,
    is_hot: 0,
    is_new: 0,
    children: [{
      name: '弹性公网IP',
      id: 19,
      pageId: 111,
      space: '6f82f13cbd3d4e09861185cea45f3c8a',
      is_open: 2,
      parent_id: 6,
      is_hot: 1,
      is_new: 0,
      child_tree: null,
    },
    {
      name: '负载均衡SLB',
      id: 21,
      pageId: 112,
      space: 'c38d8e1198034620a16fb22d6bffc94e',
      is_open: 2,
      parent_id: 6,
      is_hot: 0,
      is_new: 1,
      child_tree: null,
    },
    {
      name: '专有网络VPC',
      id: 22,
      pageId: 113,
      space: '949e045e5dd74901900f97393c2b7149',
      is_open: 2,
      parent_id: 6,
      is_hot: 0,
      is_new: 0,
      child_tree: null,
    },
    {
      name: 'NAT网关',
      id: 23,
      pageId: 114,
      space: 'c834265c130e47d38e7a2e36d88f86c0',
      is_open: 2,
      parent_id: 6,
      is_hot: 0,
      is_new: 0,
      child_tree: null,
    },
    ],
  },
  // {
  //   name: '媒体服务',
  //   icon: 'document-icon-meitifuwu',
  //   id: 7,
  //   is_open: 2,
  //   parent_id: 3,
  //   is_hot: 0,
  //   is_new: 0,
  //   children: [
  //     {
  //       name: '云渲染',
  //       id: 24,
  //       pageId: 15,
  //       space: '',
  //       is_open: 2,
  //       parent_id: 7,
  //       is_hot: 1,
  //       is_new: 0,
  //       child_tree: null,
  //     },
  //     {
  //       name: '云直播',
  //       id: 25,
  //       pageId: 16,
  //       space: '',
  //       is_open: 2,
  //       parent_id: 7,
  //       is_hot: 1,
  //       is_new: 0,
  //       child_tree: null,
  //     },
  //     {
  //       name: 'MCDN',
  //       id: 26,
  //       pageId: 17,
  //       space: '',
  //       is_open: 2,
  //       parent_id: 7,
  //       is_hot: 1,
  //       is_new: 0,
  //       child_tree: null,
  //     },
  //   ],
  // },
]

// 错误消息提示
export const ErrorClassMsg = {
  IncorrectUsernameOrPassword: '用户名或密码错误',
  FetchJsonForRequestError: '从request获取json报错，请联系管理员',
  UserNameOrPasswdError: '用户名或密码错误',
  UserLocked: '该用户已被锁定，请联系管理员',
  UserDisabled: '该用户已被禁用，请联系管理员',
  InvalidIdpStatus: '用户的IDP被禁用或处于无效状态',
  InvalidCredentialError: '无效凭证',
  UnavailableServiceError: '服务不可用，请联系管理员',
  // GetUserSuceess:           "获得用户信息成功",
  NotFoundUserError: '未找到用户信息',
}
