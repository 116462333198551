<template>
  <base-dialog @cancel="cancelDialog">
    <div slot="header">配置-包年包月套餐</div>
    <div slot="body">
      <a-form
        v-bind="formItemLayout"
        :form="form">
        <a-row>
          <a-col :span="24">
            <span class="info-title">基础服务计费项</span>
          </a-col>
        </a-row>
        <template v-for="(k, index) in form.getFieldValue('basicKeys')">
          <a-form-item
            :key="'basicKeysNames'+k"
            :label="'计费项'+ (index+1) + '名称'"
            :required="true">
            <a-input
              v-decorator="[
                `basicKeysNames[${k}]`,
                {
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    { required: true, message: '请输入计费项名称'},
                    { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{0,20}$/, message: '仅支持中文、英文、数字；20字符以内' },
                  ],
                },
              ]"
              placeholder="请输入计费项名称"
              style="width: 80%; margin-right: 8px" />
            <a-input type="hidden" v-decorator="[`basicKeysItemIds[${k}]`]" />
            <a-icon
              v-if="form.getFieldValue('basicKeys').length > 1"
              class="dynamic-delete-button"
              type="minus-circle-o"
              :disabled="form.getFieldValue('basicKeys').length === 1"
              @click="() => removeBasicKeys(k)" />
          </a-form-item>
          <a-form-item
            :key="'basicKeysDescs'+k"
            label="套餐说明"
            :required="true">
            <a-textarea :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                `basicKeysDescs[${k}]`,
                {
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    { required: true, message: '请输入套餐说明' },
                    { max: 100, message: '100字符以内' },
                  ],
                },
              ]"
              placeholder="请输入套餐说明"
              style="width: 80%; margin-right: 8px" />
          </a-form-item>
        </template>
        <a-form-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 80%" @click="addBasicKeys">
            <a-icon type="plus" /> 添加计费项
          </a-button>
        </a-form-item>
        <a-row>
          <a-col :span="24">
            <span class="info-title">增值服务计费项</span>
          </a-col>
        </a-row>
        <template v-for="(k, index) in form.getFieldValue('extraKeys')">
          <a-form-item
            :key="'extraKeysNames'+k"
            :label="'计费项'+ (index+1) + '名称'"
            :required="true">
            <a-input
              v-decorator="[
                `extraKeysNames[${k}]`,
                {
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    { required: true, message: '请输入计费项名称'},
                    { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{0,20}$/, message: '仅支持中文、英文、数字；20字符以内' },
                  ],
                },
              ]"
              placeholder="请输入计费项名称"
              style="width: 80%; margin-right: 8px" />
            <a-input type="hidden" v-decorator="[`extraKeysItemIds[${k}]`]" />
            <a-icon
              class="dynamic-delete-button"
              type="minus-circle-o"
              @click="() => removeExtraKeys(k)" />
          </a-form-item>
          <a-form-item
            :key="'extraKeysDescs'+k"
            label="套餐说明"
            :required="true">
            <a-textarea :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
                `extraKeysDescs[${k}]`,
                {
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    { required: true, message: '请输入套餐说明' },
                    { max: 100, message: '100字符以内' },
                  ],
                },
              ]"
              placeholder="请输入套餐说明"
              style="width: 80%; margin-right: 8px" />
          </a-form-item>
        </template>
        <a-form-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 80%" @click="addExtraKeys">
            <a-icon type="plus" /> 添加计费项
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div slot="footer">
      <a-button type="primary" @click="handleConfirm" :loading="loading">完成配置并启用</a-button>
      <a-button @click="cancelDialog">{{ $t('dialog.cancel') }}</a-button>
    </div>
  </base-dialog>
</template>

<script>
import DialogMixin from '@/mixins/dialog'
import WindowsMixin from '@/mixins/windows'
import { maxBillingItem } from '../constants'

export default {
  name: 'CloudProductPrepaidConfigDialog',
  mixins: [DialogMixin, WindowsMixin],
  data () {
    return {
      loading: false,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },
      basicKey: 0,
      extraKey: 0,
    }
  },
  beforeCreate () {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('basicKeys', { initialValue: [], preserve: true })
    this.form.getFieldDecorator('extraKeys', { initialValue: [], preserve: true })
  },
  created () {
    this.fetchDetail()
  },
  methods: {
    fetchDetail () {
      this.$http.get('/v2/cloud_product_prepaid_items/config', {
        params: {
          product_id: this.params.row.product_id,
        },
      }).then(res => {
        console.log('res.data:', res.data)
        // if (res.data.err_code !== 0) {
        //   this.$message.error(res.data.message)
        //   return
        // }
        if (res.data) {
          const { form } = this
          const items = res.data.items || []
          const basicKeysItems = items.filter(item => item.billing_item_type === 1).sort((a, b) => a.seq - b.seq)
          if (basicKeysItems.length) {
            // 先更新basicKeys，再更新basicKeysNames和basicKeysDescs
            const basicKeys = form.getFieldValue('basicKeys')
            basicKeysItems.forEach((item, index) => {
              basicKeys.push(this.basicKey++)
            })
            form.setFieldsValue({
              basicKeys,
            })
            this.$nextTick(() => {
              form.setFieldsValue({
                basicKeysItemIds: basicKeysItems.map(item => item.item_id),
                basicKeysNames: basicKeysItems.map(item => item.billing_item),
                basicKeysDescs: basicKeysItems.map(item => item.plan_description),
              })
            })
          } else {
            // 默认添加一个基础计费项
            this.addBasicKeys()
          }
          const extraKeysItems = items.filter(item => item.billing_item_type === 2).sort((a, b) => a.seq - b.seq)
          if (extraKeysItems.length) {
            // 先更新extraKeys，再更新extraKeysNames和extraKeysDescs
            const extraKeys = form.getFieldValue('extraKeys')
            extraKeysItems.forEach((item, index) => {
              extraKeys.push(this.extraKey++)
            })
            form.setFieldsValue({
              extraKeys,
            })
            this.$nextTick(() => {
              form.setFieldsValue({
                extraKeysItemIds: extraKeysItems.map(item => item.item_id),
                extraKeysNames: extraKeysItems.map(item => item.billing_item),
                extraKeysDescs: extraKeysItems.map(item => item.plan_description),
              })
            })
          }
        }
      })
    },
    removeBasicKeys (k) {
      const { form } = this
      const basicKeys = form.getFieldValue('basicKeys')
      if (basicKeys.length === 1) {
        return
      }
      form.setFieldsValue({
        basicKeys: basicKeys.filter(key => key !== k),
      })
    },
    addBasicKeys () {
      const { form } = this
      const basicKeys = form.getFieldValue('basicKeys')
      if (basicKeys.length >= maxBillingItem) {
        this.$message.error(`最多添加${maxBillingItem}个计费项`)
        return
      }
      const nextKeys = basicKeys.concat(this.basicKey++)
      form.setFieldsValue({
        basicKeys: nextKeys,
      })
    },
    removeExtraKeys (k) {
      const { form } = this
      const extraKeys = form.getFieldValue('extraKeys')
      form.setFieldsValue({
        extraKeys: extraKeys.filter(key => key !== k),
      })
    },
    addExtraKeys () {
      const { form } = this
      const extraKeys = form.getFieldValue('extraKeys')
      if (extraKeys.length >= maxBillingItem) {
        this.$message.error(`最多添加${maxBillingItem}个计费项`)
        return
      }
      const nextKeys = extraKeys.concat(this.extraKey++)
      form.setFieldsValue({
        extraKeys: nextKeys,
      })
    },
    doConfig (data) {
      return this.$http.post('/v2/cloud_product_prepaid_items/config', data)
    },
    async handleConfirm () {
      this.loading = true
      try {
        const values = await this.form.validateFields()
        const { basicKeys, basicKeysItemIds, basicKeysNames, basicKeysDescs, extraKeys, extraKeysItemIds, extraKeysNames, extraKeysDescs } = values
        console.log('basicKeys: ', basicKeys, basicKeysItemIds, basicKeysNames, basicKeysDescs)
        console.log('extraKeys: ', extraKeys, extraKeysItemIds, extraKeysNames, extraKeysDescs)
        const items = []
        basicKeys.forEach(key => {
          items.push({
            item_id: basicKeysItemIds[key],
            billing_item: basicKeysNames[key],
            plan_description: basicKeysDescs[key],
            billing_item_type: 1,
          })
        })
        extraKeys.forEach(key => {
          items.push({
            item_id: extraKeysItemIds[key],
            billing_item: extraKeysNames[key],
            plan_description: extraKeysDescs[key],
            billing_item_type: 2,
          })
        })
        const data = {
          product_id: this.params.row.product_id,
          items,
        }
        console.log('data: ', data)

        this.loading = true
        await this.doConfig(data)
        // if (res.data.err_code !== 0) {
        //   this.$message.error(res.data.message)
        //   this.loading = false
        //   return
        // }
        this.$message.success('配置成功')
        this.loading = false
        this.params.refresh()
        this.cancelDialog()
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>
<style>
.info-title{
  font-size: 15px;
  font-weight: 500;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
